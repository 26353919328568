import React, { useEffect } from 'react';
import { getPlanAmount } from 'utils/getPlanAmount';
import { getPlanQuota } from 'utils/getPlanQuota';
import {
  canMonthlyForPlan,
  canMonthlyBillingInterval,
} from 'utils/billingIntervalFeatureFlagUtils';
import Loader from 'components/Loader';
import Pricing from '../Pricing';
import useConnect from './connect';
import { CurrentPlans } from './CurrentPlans';
import {
  Container,
  Section,
  Header,
  HeaderNeedMore,
  Title,
  PlansContainer,
  Plan,
  WantToUpgradeMessage,
  // WantToChangeBillingCycleMessage,
  PurchaseAdditionalMessage,
  BillingMessage,
  LoaderContainer,
  StrongText,
  RadioInput,
} from './styles';
import { registerTransactionSuccess } from '../../../../utils/transactionSuccessLog';
import { ManageUsers } from './ManageUsers';
import { TooltipStyles } from '../../../../components/Tooltip';
import QuotaConditionsModal from '../../../Common/WrongPlanChoice/QuotaConditionsModal';

const Billing = () => {
  const {
    email,
    plan,
    seats,
    purchasedPlans,
    isLoadingPurchasedPlans,
    upgradePaths,
    askIfNeedsMore,
    typeOfError,
    redirectError,
    closeRedirectError,
    typeOfSuccess,
    sessionId,
    messageOfSuccess,
    closeRedirectSuccess,
    push,
    teamPlan,
    userPlan,
    billingIntervalFeatureFlag,
    billingInterval,
    orderedInterval,
    handleSeatsChange,
    handleBillingIntervalChange,
    managePlans,
    managePlansStatus,
    hasPurchasedIndividualPaypalExpiringPlan,
    hasPurchasedIndividualStripePlan,
    domainPlans,
  } = useConnect();

  useEffect(() => {
    if (sessionId != null) {
      registerTransactionSuccess(sessionId);
    }
  }, [sessionId]);

  if (plan && orderedInterval) {
    return (
      <Pricing
        plan={plan}
        seats={seats}
        billingInterval={orderedInterval}
        canMonthly={canMonthlyForPlan(plan, seats)}
      />
    );
  }

  if (isLoadingPurchasedPlans) {
    return (
      <LoaderContainer>
        <Loader size={50} />
      </LoaderContainer>
    );
  }

  return (
    <Container data-test-id="billing-dashboard">
      <TooltipStyles />
      <QuotaConditionsModal />
      {typeOfSuccess ? (
        <BillingMessage
          variant="SUCCESS"
          isOpen
          onClose={closeRedirectSuccess}
          title="Thanks for your purchase!"
          message={messageOfSuccess}
          id={`success-${typeOfSuccess}`.toLowerCase()}
        />
      ) : (
        <BillingMessage
          variant="WARNING"
          isOpen={redirectError !== undefined}
          onClose={closeRedirectError}
          title={redirectError?.title || ''}
          message={redirectError?.message}
          id={typeOfError as string | undefined}
        />
      )}
      {(purchasedPlans.length > 0 || userPlan === 'FREE') && (
        <Section>
          <>
            <Header>
              <Title>
                Your current plan{purchasedPlans.length > 1 ? 's' : null}
              </Title>
            </Header>
            <PlansContainer data-test-id="current-plans">
              <CurrentPlans
                managePlans={managePlans}
                purchasedPlans={purchasedPlans}
                email={email}
                isLoadingPurchasedPlans={isLoadingPurchasedPlans}
                managedPlanStatus={managePlansStatus}
                userPlan={userPlan}
                canMonthly={canMonthlyForPlan(plan, seats)}
              />
            </PlansContainer>
            {/*             <> */}
            {askIfNeedsMore && (
              <Section>
                <WantToUpgradeMessage>
                  <StrongText>Want to upgrade or downgrade?</StrongText>
                  <span>
                    Purchase the new plan you want to use and then cancel your
                    former plan. In case of upgrade,{' '}
                    <a
                      href="https://support.yet-another-mail-merge.com/hc/en-us/requests/new"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      contact us
                    </a>{' '}
                  </span>
                  to ask for your pro-rated refund.
                </WantToUpgradeMessage>
              </Section>
            )}
            {/*               <WantToChangeBillingCycleMessage>
                <StrongText>Want to change your billing cycle?</StrongText>
                <span>
                  {' '}
                  <a
                    href="https://support.yet-another-mail-merge.com/hc/en-us/requests/new"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact us.
                  </a>{' '}
                </span>
              </WantToChangeBillingCycleMessage>
            </> */}
            {hasPurchasedIndividualPaypalExpiringPlan &&
              !hasPurchasedIndividualStripePlan && (
                <WantToUpgradeMessage>
                  If you purchased with PayPal anonymously (without creating an
                  actual PayPal account), you won&#39;t be able to change your
                  payment method. If you need to change your payment method, you
                  must purchase a new subscription below and cancel your current
                  subscription
                </WantToUpgradeMessage>
              )}
          </>
        </Section>
      )}
      <Section>
        {upgradePaths.length > 0 && (
          <>
            <HeaderNeedMore>
              <Title>{askIfNeedsMore ? 'Need more?' : 'Upgrade'}</Title>
              {askIfNeedsMore && (
                <PurchaseAdditionalMessage>
                  Purchase additional plans for your other domains
                </PurchaseAdditionalMessage>
              )}
            </HeaderNeedMore>
            {billingIntervalFeatureFlag.includes('ALL') && (
              <RadioInput
                billingInterval={billingInterval}
                onChange={handleBillingIntervalChange}
              />
            )}
            <PlansContainer data-test-id="upgrade-plans">
              {upgradePaths.map((path) => {
                if (path === 'PERSONAL') {
                  return (
                    <Plan
                      email={email}
                      amount={getPlanAmount(
                        'PERSONAL',
                        teamPlan.seats,
                        canMonthlyBillingInterval('PERSONAL', billingInterval),
                      )}
                      plan="PERSONAL"
                      key="personal"
                      quota={getPlanQuota('PERSONAL')}
                      canMonthly={canMonthlyForPlan('PERSONAL')}
                      billingInterval={billingInterval}
                      billingIntervalFeatureFlag={billingIntervalFeatureFlag}
                      currency="USD"
                      onBillingIntervalChange={handleBillingIntervalChange}
                      onSubscribe={() =>
                        push(
                          `?plan=PERSONAL&billingInterval=${canMonthlyBillingInterval(
                            'PERSONAL',
                            billingInterval,
                          )}`,
                        )
                      }
                    />
                  );
                }

                if (path === 'PROFESSIONAL') {
                  return (
                    <Plan
                      email={email}
                      amount={getPlanAmount(
                        'PROFESSIONAL',
                        teamPlan.seats,
                        canMonthlyBillingInterval(
                          'PROFESSIONAL',
                          billingInterval,
                        ),
                      )}
                      plan="PROFESSIONAL"
                      key="professional"
                      quota={getPlanQuota('PROFESSIONAL')}
                      canMonthly={canMonthlyForPlan('PROFESSIONAL')}
                      billingInterval={billingInterval}
                      billingIntervalFeatureFlag={billingIntervalFeatureFlag}
                      onBillingIntervalChange={handleBillingIntervalChange}
                      currency="USD"
                      onSubscribe={() =>
                        push(
                          `?plan=PROFESSIONAL&billingInterval=${canMonthlyBillingInterval(
                            'PROFESSIONAL',
                            billingInterval,
                          )}`,
                        )
                      }
                    />
                  );
                }

                if (path === 'TEAM') {
                  const [, host] = email.split('@');
                  return (
                    <Plan
                      email={host}
                      amount={getPlanAmount(
                        'TEAM',
                        teamPlan.seats,
                        canMonthlyBillingInterval(
                          'TEAM',
                          billingInterval,
                          teamPlan.seats,
                        ),
                      )}
                      plan="TEAM"
                      key="team"
                      quota={getPlanQuota('TEAM')}
                      seats={teamPlan.seats}
                      canMonthly={canMonthlyForPlan('TEAM', teamPlan.seats)}
                      billingInterval={billingInterval}
                      billingIntervalFeatureFlag={billingIntervalFeatureFlag}
                      onBillingIntervalChange={handleBillingIntervalChange}
                      currency="USD"
                      onSeatsChange={handleSeatsChange}
                      onSubscribe={() =>
                        push(
                          `?plan=TEAM&seats=${
                            teamPlan.seats
                          }&billingInterval=${canMonthlyBillingInterval(
                            'TEAM',
                            billingInterval,
                            teamPlan.seats,
                          )}`,
                        )
                      }
                    />
                  );
                }

                return (
                  <Plan
                    plan="UNLIMITED"
                    key="unlimited"
                    quota={getPlanQuota('UNLIMITED')}
                  />
                );
              })}
            </PlansContainer>
          </>
        )}
      </Section>

      {domainPlans && domainPlans.length > 0 && (
        <Section>
          <ManageUsers domainPlans={domainPlans} />
        </Section>
      )}
    </Container>
  );
};

export default Billing;
