import { captureException } from '@sentry/react';
import { useDomain } from 'data/hooks/useDomain';
import { useManagePlans } from 'data/hooks/useManagePlans';
import { useMe } from 'data/hooks/useMe';
import { usePurchasedPlans } from 'data/hooks/usePurchasedPlans';
import { NewPlan, normalizePlan } from 'model/plan';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';
import { QueryStatus } from 'react-query';
import { availablePlans, getPlanId } from './constants';
import { UpgradePaths, RedirectError, RedirectSuccess } from './types';
import { timestampDifference } from '../../../../utils/dates';
import { isGmailAddress } from '../../../../utils/userUtils';
import {
  getBillingIntervalFeatureFlag,
  getDefaultBillingCycleFeatureFlag,
} from '../../../../utils/billingIntervalFeatureFlagUtils';
import { SuccessMessages } from './messages';
import {
  logStripeCheckoutError,
  logStripeCheckoutSuccess,
} from '../../../../utils/checkoutLogging';

/**
 * check if {purchasedPlans} contains at least one of {planTypes} that is stripe
 * @param planTypes
 * @param purchasedPlans
 */
function hasStripePlan(
  planTypes: ReturnType<typeof normalizePlan>[],
  purchasedPlans: ReturnType<typeof usePurchasedPlans>['purchasedPlans'],
) {
  return purchasedPlans.some(
    (purchasedPlan) =>
      purchasedPlan.paymentSource === 'stripe' &&
      planTypes.includes(purchasedPlan.planName),
  );
}

/**
 * if {expiring} - check if {purchasedPlans} contains at least one expiring paypal plan (31 days or less)
 * if not {expiring} - check if {purchasedPlans} contains at least one not expiring paypal plan
 * @param planTypes
 * @param purchasedPlans
 * @param expiring
 */
function hasPaypalPlan(
  planTypes: ReturnType<typeof normalizePlan>[],
  purchasedPlans: ReturnType<typeof usePurchasedPlans>['purchasedPlans'],
  expiring: boolean,
) {
  const now = Date.now();
  if (expiring) {
    return purchasedPlans.some(
      (purchasedPlan) =>
        purchasedPlan.paymentSource === 'paypal' &&
        planTypes.includes(purchasedPlan.planName) &&
        timestampDifference(now, purchasedPlan.endTimestamp) <= 31,
    );
  }

  return purchasedPlans.some(
    (purchasedPlan) =>
      purchasedPlan.paymentSource === 'paypal' &&
      planTypes.includes(purchasedPlan.planName) &&
      timestampDifference(now, purchasedPlan.endTimestamp) > 31,
  );
}

function useUpgradePaths(
  purchasedPlans: ReturnType<typeof usePurchasedPlans>['purchasedPlans'],
): UpgradePaths {
  const { me } = useMe({ keepPreviousData: true });
  if (!me) return [];

  const isGmail = isGmailAddress(me.email);

  // GMAIL
  if (isGmail) {
    if (
      me.plan !== 'FREE' &&
      purchasedPlans != null &&
      purchasedPlans.length > 0
    ) {
      if (
        hasStripePlan(['PERSONAL'], purchasedPlans) ||
        hasPaypalPlan(['PERSONAL'], purchasedPlans, false)
      ) {
        // if user is not free (PAID)
        // and has purchased at least one plan
        // and has a valid stripe plan or not expiring paypal plan
        // no upgrades available
        return [];
      }
    }

    // if user is free
    // if user does not have purchased plans
    // if user has expiring paypal plan
    // PERSONAL upgrade is available
    return ['PERSONAL'];
  }

  // G SUITE
  if (
    me.plan !== 'FREE' &&
    purchasedPlans != null &&
    purchasedPlans.length > 0
  ) {
    if (
      hasStripePlan(['PROFESSIONAL'], purchasedPlans) ||
      hasPaypalPlan(['PROFESSIONAL'], purchasedPlans, false)
    ) {
      // if user is not free (PAID or DOMAIN_PAID)
      // and has purchased at least one plan
      // and has a valid stripe plan or not expiring paypal plan
      // only TEAM and UNLIMITED upgrades available (no PROFESSIONAL)
      return ['TEAM', 'UNLIMITED'];
    }
  }

  // if user is free
  // if user does not have purchased plans
  // if user has expiring paypal plan
  // all G Suite upgrades are available
  return ['PROFESSIONAL', 'TEAM', 'UNLIMITED'];
}

function useTeamPlan() {
  const [teamPlan, setTeamPlan] = useState(
    availablePlans[getPlanId('TEAM_EUR_5_MONTHLY')],
  );

  const handleSeatsChange = useCallback((seats: number) => {
    const matchingTeamPlan = Object.values(availablePlans).find(
      (plan) => plan.plan === 'TEAM' && plan.seats === seats,
    );
    if (!matchingTeamPlan) return;
    setTeamPlan(matchingTeamPlan);
  }, []);

  return { teamPlan, handleSeatsChange };
}

function useBillingInterval() {
  const [billingInterval, setBillingInterval] = useState(
    getDefaultBillingCycleFeatureFlag(),
  );

  const handleBillingIntervalChange = useCallback((interval: string) => {
    setBillingInterval(interval);
  }, []);

  return { billingInterval, handleBillingIntervalChange };
}

function useOrderedPlan() {
  const params = useQueryParams();
  const rawPlan = params.get('plan');
  const rawSeats = params.get('seats');
  const rawBillingInterval = params.get('billingInterval');
  const plan = normalizePlan(rawPlan);
  const seats =
    rawSeats && !Number.isNaN(Number(rawSeats)) ? Number(rawSeats) : undefined;
  const orderedInterval =
    rawBillingInterval && ['MONTHLY', 'YEARLY'].includes(rawBillingInterval)
      ? rawBillingInterval
      : undefined;
  return { plan, seats, orderedInterval };
}

function useRedirectedSuccess() {
  const { replace } = useHistory();
  const params = useQueryParams();
  const paramKey = 'success';
  const sessionIdKeyKey = 'sessionId';
  const closeRedirectSuccess = useCallback(() => {
    replace(`${window.location.pathname}`);
  }, [replace]);
  const typeOfSuccess = params.get(paramKey) as RedirectSuccess | null;
  const sessionId = params.get(sessionIdKeyKey);
  return {
    sessionId,
    typeOfSuccess,
    messageOfSuccess: typeOfSuccess
      ? SuccessMessages[typeOfSuccess]
      : undefined,
    closeRedirectSuccess,
  };
}

function useRedirectedError() {
  const { replace } = useHistory();
  const params = useQueryParams();
  const paramKey = 'error';
  const closeRedirectError = useCallback(() => {
    replace(`${window.location.pathname}`);
  }, [replace]);
  const typeOfError = params.get(paramKey) as RedirectError | null;
  const errors: {
    [Key in RedirectError]: { title: string; message: string };
  } = {
    'plan-reserved-for-workspace-accounts': {
      title: `The plan you chose is reserved for Google Workspace accounts`,
      message: `If you want to purchase it, please log in with a Google Workspace account first.`,
    },
    'plan-reserved-for-individual-accounts': {
      title: 'The plan you chose is reserved for Gmail.com accounts',
      message: `If you want to purchase it, please log in with a Gmail.com account first.`,
    },
    'plan-already-bought': {
      title: `Your domain already has this plan`,
      message: `Please choose another number of users.`,
    },
    'personal-plan-already-bought': {
      title: `You already have this plan on your account`,
      message: `If you want to purchase it for a different account, please log in with another Google account first.`,
    },
    'team-plan-already-bought': {
      title: `You already have a team plan`,
      message: `If you want to purchase a professional plan for another Google workspace account, please log in with this account first.`,
    },
  };
  return {
    typeOfError,
    error: typeOfError ? errors[typeOfError] : undefined,
    closeRedirectError,
  };
}

function useGateKeepPlan(
  requestedPlan: UpgradePaths[number] | undefined,
  upgradePaths: ReturnType<typeof useUpgradePaths>,
  purchasedPlans: ReturnType<typeof usePurchasedPlans>['purchasedPlans'],
  isFetching: ReturnType<typeof usePurchasedPlans>['isFetching'],
  requestedSeats: number | undefined,
  me: any,
) {
  const { replace } = useHistory();

  if (!requestedPlan) return;

  if (isFetching && !purchasedPlans.length) return;

  if (requestedPlan === 'UNLIMITED') {
    replace(`${window.location.pathname}`);
    return;
  }

  const hasPersonalPlan = purchasedPlans?.find(
    (purchasedPlan) => purchasedPlan.planName === 'PERSONAL',
  );

  const hasProfessionalPlan = purchasedPlans?.find(
    (purchasedPlan) => purchasedPlan.planName === 'PROFESSIONAL',
  );

  if (!upgradePaths.includes(requestedPlan)) {
    let error: RedirectError;
    let showWrongPlanPage: boolean = false;

    if (
      (hasPersonalPlan && requestedPlan === 'PERSONAL') ||
      (hasProfessionalPlan && requestedPlan === 'PROFESSIONAL')
    ) {
      error = 'personal-plan-already-bought';
    } else if (!hasPersonalPlan && requestedPlan === 'PERSONAL') {
      error = 'plan-reserved-for-individual-accounts';
      showWrongPlanPage = true;
    } else {
      error = 'plan-reserved-for-workspace-accounts';
      showWrongPlanPage = true;
    }

    if (!error) return;
    if (showWrongPlanPage) {
      let path = `/wrong-plan?error=${error}&plan=${requestedPlan}`;
      if (requestedSeats) {
        path += `&seats=${requestedSeats}`;
      }

      if (!me) {
        captureException(new Error('me is null at useGateKeepPlan'));
      }

      replace(path);
    } else {
      replace(`${window.location.pathname}?error=${error}`);
    }
  }
}

const useConnect = () => {
  const { push } = useHistory();
  const { me } = useMe({ keepPreviousData: true });
  const { domain, status: domainPlanStatus } = useDomain(
    { userId: me?.email, includeStats: false },
    { keepPreviousData: true },
  );
  const { plan, seats, orderedInterval } = useOrderedPlan();
  const {
    purchasedPlans,
    status: purchasedPlansStatus,
    isFetching,
  } = usePurchasedPlans({
    userIsWorkspace: me?.email ? !isGmailAddress(me.email) : false,
    domainPlan: domain?.plan,
    domain: domain?.domain,
  });
  const { teamPlan, handleSeatsChange } = useTeamPlan();
  const billingIntervalFeatureFlag = getBillingIntervalFeatureFlag();
  const { billingInterval, handleBillingIntervalChange } = useBillingInterval();
  const upgradePaths = useUpgradePaths(purchasedPlans);
  useGateKeepPlan(
    plan as UpgradePaths[number] | undefined,
    upgradePaths,
    purchasedPlans,
    isFetching,
    seats,
    me,
  );
  const email = me?.email;
  const subscriptionIds = purchasedPlans
    .filter((purchasedPlan) => purchasedPlan.paymentSource === 'stripe')
    .map((purchasedPlan) => purchasedPlan.subscriptionId);
  const { managePlans, status: managePlansStatus } = useManagePlans({
    userId: email,
    returnUrl: window.location.href,
    subscriptionIds,
  });
  const askIfNeedsMore = purchasedPlans.some(
    (purchasedPlan) => purchasedPlan.planName === 'TEAM',
  );
  const hasPurchasedIndividualPaypalExpiringPlan = hasPaypalPlan(
    ['PERSONAL', 'PROFESSIONAL'],
    purchasedPlans,
    true,
  );
  const hasPurchasedIndividualStripePlan = hasStripePlan(
    ['PERSONAL', 'PROFESSIONAL'],
    purchasedPlans,
  );
  const {
    typeOfError,
    error: redirectError,
    closeRedirectError,
  } = useRedirectedError();
  const {
    sessionId,
    typeOfSuccess,
    messageOfSuccess,
    closeRedirectSuccess,
  } = useRedirectedSuccess();

  const isLoadingPurchasedPlans =
    purchasedPlansStatus === QueryStatus.Loading ||
    domainPlanStatus === QueryStatus.Loading;

  const domainPlans = useMemo(
    () =>
      purchasedPlans.filter(
        (purchasedPlan) => purchasedPlan.planName === 'TEAM',
      ),
    [purchasedPlans],
  );

  useEffect(() => {
    if (typeOfSuccess && me?.email) {
      logStripeCheckoutSuccess(me.email, typeOfSuccess, sessionId || undefined);
    }
  }, [typeOfSuccess, me?.email, sessionId]);

  useEffect(() => {
    if (typeOfError && me?.email) {
      logStripeCheckoutError(me.email, typeOfError);
    }
  }, [typeOfError, me?.email]);

  return {
    sessionId,
    plan: plan as NewPlan,
    seats,
    userPlan: me?.plan,
    purchasedPlans,
    isLoadingPurchasedPlans,
    upgradePaths,
    email,
    teamPlan,
    billingIntervalFeatureFlag,
    billingInterval,
    orderedInterval,
    askIfNeedsMore,
    typeOfError,
    redirectError,
    closeRedirectError,
    typeOfSuccess,
    messageOfSuccess,
    closeRedirectSuccess,
    push,
    managePlans,
    managePlansStatus,
    handleSeatsChange,
    handleBillingIntervalChange,
    domainPlan: domain,
    hasPurchasedIndividualPaypalExpiringPlan,
    hasPurchasedIndividualStripePlan,
    domainPlans,
  };
};

export default useConnect;
