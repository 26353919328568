import React, { memo, useMemo, useCallback } from 'react';
import { ReactComponent as PaypalIcon } from 'assets/images/icons/paypal-billing-icon.svg';
import { ReactComponent as StripeIcon } from 'assets/images/icons/stripe-billing-icon.svg';
import Select from './Select';
import SelectBillingInterval from './SelectBillingInterval';
import TruncateText from './TruncateText';
import { Tooltip } from '../Tooltip';
import useLogic, { getFormattedValue } from './logic';
import {
  Bottom,
  BottomPrice,
  Button,
  Color,
  Container,
  CurrentContainer,
  Link,
  Text,
  BoldText,
  Title,
  PlaceholderText,
  PlaceholderButton,
  IconedManagePlanContainer,
  HeadContainer,
  InfoIcon,
  RecipientsContainer,
} from './styles';
import { Props } from './types';
import { MANAGE_PLAN_PAYPAL_LINK } from './constants';
import { formatShortMonthDayYear } from '../../utils/dates';
import { CopyToClipboard } from '../CopyToClipboard';

const QuotaConditionsMessage = {
  WORKSPACE: (
    <>
      This email quota is subject to certain conditions.{' '}
      <i>Click to learn more</i>
    </>
  ),
  PERSONAL: (
    <>
      You can only send emails with personalized attachments to 100 recipients /
      day. <i>Click to learn more</i>
    </>
  ),
};

const Plan = ({
  isLoading,
  managePlanIsLoading,
  amount,
  className,
  email = '-',
  isCurrent,
  onManage,
  onSubscribe = () => {},
  onSeatsChange = () => {},
  onBillingIntervalChange = () => {},
  plan,
  quota,
  seats,
  currency = 'USD',
  paymentSource = 'stripe',
  taxValue = 0,
  endTimestamp,
  status,
  subscriptionId,
  canMonthly,
  billingIntervalFeatureFlag,
  billingInterval,
}: Props) => {
  const {
    isMobile,
    isTeam,
    showAmount,
    showSeats,
    title,
    triggerQuotaModal,
    openUserQuotaExplanationLink,
    isWorkspace,
  } = useLogic({
    amount,
    plan,
    seats,
  });

  const formattedAmount = useMemo(
    () => amount && getFormattedValue(amount, currency),
    [amount, currency],
  );

  const formattedEndDate = useMemo(
    () =>
      endTimestamp
        ? formatShortMonthDayYear(new Date(Number(endTimestamp))) // todo: for some reason, endTimestamp is string here
        : '',
    [endTimestamp],
  );

  const formattedBillingTextForUpgrade = useMemo(() => {
    return `/ ${
      billingInterval === 'MONTHLY' && canMonthly ? 'month' : 'year'
    }`;
  }, [billingInterval]);

  const formattedBillingTextForCurrent = useMemo(() => {
    return `/ ${billingInterval === 'MONTHLY' ? 'month' : 'year'}`;
  }, [billingInterval]);

  const onSeatsChangeForceYearly = useCallback(
    (seatsOrdered: number) => {
      if (onSeatsChange) {
        onSeatsChange(seatsOrdered);
        if (!billingIntervalFeatureFlag?.includes('ALL')) {
          onBillingIntervalChange('YEARLY');
        }
      }
    },
    [onSeatsChange],
  );

  if (isLoading) {
    return (
      <Container className={className} data-test-id={plan}>
        <PlaceholderText />
        <PlaceholderText />
        <PlaceholderText />
        <PlaceholderText />
        <PlaceholderButton />
      </Container>
    );
  }

  if (plan === 'UNLIMITED') {
    const contactSalesButton = (
      <Link
        href="mailto:sales@yet-another-mail-merge.com"
        rel="noopener noreferrer nofollow"
        role="button"
      >
        Contact sales
      </Link>
    );

    if (!isCurrent) {
      // to purchase unlimited
      return (
        <Container className={className} data-test-id={plan}>
          <Title>{title}</Title>
          <Text>Unlimited users</Text>

          <RecipientsContainer>
            <BoldText>
              <Color>Up to</Color>
              &nbsp;{quota}&nbsp;
              <Color>recipients {isTeam ? '/ user ' : null}/ day</Color>
            </BoldText>
            <Tooltip
              title={QuotaConditionsMessage.WORKSPACE}
              arrow
              placement="bottom"
              onClick={triggerQuotaModal}
            >
              <InfoIcon />
            </Tooltip>
          </RecipientsContainer>
          {isMobile ? <Bottom>{contactSalesButton}</Bottom> : null}
          {!isMobile ? (
            <>
              <span />
              {contactSalesButton}
            </>
          ) : null}
        </Container>
      );
    }

    // current plans unlimited
    return (
      <CurrentContainer className={className} data-test-id={plan}>
        <Title>{title}</Title>
        <BoldText>
          {quota}&nbsp;
          <Color>recipients {isTeam ? '/ user ' : null}/ day</Color>
        </BoldText>
        {isMobile ? <Bottom>{contactSalesButton}</Bottom> : null}
        {!isMobile ? (
          <>
            <span />
            <span />
            <IconedManagePlanContainer>
              <span />
              {contactSalesButton}
            </IconedManagePlanContainer>
          </>
        ) : null}
      </CurrentContainer>
    );
  }

  if (isCurrent && plan === 'FREE') {
    // free plan
    return (
      <CurrentContainer className={className} data-test-id={plan}>
        <div>
          <Title>{title}</Title>
          <Text>
            <TruncateText text={email} />
          </Text>
        </div>
        <BoldText>
          {quota}&nbsp;
          <Color>recipients {isTeam ? '/ user ' : null}/ day</Color>
        </BoldText>
      </CurrentContainer>
    );
  }

  if (isCurrent) {
    const managePlanButton = (() => {
      if (managePlanIsLoading) return <PlaceholderButton />;
      const managePlanLink =
        paymentSource === 'paypal' ? MANAGE_PLAN_PAYPAL_LINK : onManage;
      if (managePlanLink && plan !== 'FREE') {
        return (
          <Link
            data-test-id={`manage-${plan.toLowerCase()}-plan`}
            href={managePlanLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            Manage plan
          </Link>
        );
      }
      return null;
    })();

    // current plans (owner)
    return (
      <CurrentContainer className={className} data-test-id={plan}>
        <HeadContainer>
          <Title>
            <TruncateText text={title} />
          </Title>
          <Text>
            <TruncateText className="bold" text={email} />
            {showSeats ? <Color>&nbsp;({seats} users)</Color> : null}
          </Text>
          <CopyToClipboard text={subscriptionId || ''}>
            <Text>
              <TruncateText text={`ID: ${subscriptionId}`} />
            </Text>
          </CopyToClipboard>
        </HeadContainer>
        <BoldText>
          {quota}&nbsp;
          <Color>recipients {isTeam ? '/ user ' : null}/ day</Color>
        </BoldText>
        {isMobile ? (
          <>
            <BoldText>
              <Color>Renews</Color>&nbsp;
              {formattedEndDate}
            </BoldText>
            <Bottom>
              {showAmount ? (
                <BottomPrice>
                  <Text>{formattedAmount}</Text>
                  <Color>
                    {formattedBillingTextForCurrent}
                    {taxValue > 0 && ' (excl. tax)'}
                  </Color>
                </BottomPrice>
              ) : null}
              <IconedManagePlanContainer>
                {paymentSource === 'stripe' ? <StripeIcon /> : <PaypalIcon />}
                {managePlanButton}
              </IconedManagePlanContainer>
            </Bottom>
          </>
        ) : (
          <>
            {showAmount ? (
              <BoldText>
                {formattedAmount}&nbsp;
                <Color>
                  {formattedBillingTextForCurrent}
                  {taxValue > 0 && ' (excl. tax)'}
                </Color>
              </BoldText>
            ) : (
              <span />
            )}
            <BoldText>
              <Color>{status === 'ACTIVE' ? 'Renews' : 'Ends'}</Color>&nbsp;
              {formattedEndDate}
            </BoldText>
            <IconedManagePlanContainer>
              <Tooltip
                title={
                  paymentSource === 'stripe'
                    ? 'Payment via Stripe'
                    : 'Payment via Paypal'
                }
                arrow
                placement="bottom"
              >
                {paymentSource === 'stripe' ? <StripeIcon /> : <PaypalIcon />}
              </Tooltip>
              {managePlanButton}
            </IconedManagePlanContainer>
          </>
        )}
      </CurrentContainer>
    );
  }

  // to purchase not unlimited
  return (
    <Container className={className} data-test-id={plan}>
      <Title>{title}</Title>
      <Text>
        {showSeats ? (
          <Select seats={seats || 5} onChange={onSeatsChangeForceYearly} />
        ) : (
          'Single user'
        )}
      </Text>
      <RecipientsContainer>
        <BoldText>
          <Color>Up to</Color>
          &nbsp;{quota}&nbsp;
          <Color>recipients {isTeam ? '/ user ' : null}/ day</Color>
        </BoldText>
        <Tooltip
          title={
            isWorkspace
              ? QuotaConditionsMessage.WORKSPACE
              : QuotaConditionsMessage.PERSONAL
          }
          arrow
          placement="bottom"
          onClick={
            isWorkspace ? triggerQuotaModal : openUserQuotaExplanationLink
          }
        >
          <InfoIcon />
        </Tooltip>
      </RecipientsContainer>
      {isMobile ? (
        <Bottom>
          {canMonthly && !billingIntervalFeatureFlag?.includes('ALL') ? (
            <SelectBillingInterval
              showAmount={showAmount}
              plan={plan}
              seats={seats}
              currency={currency}
              billingInterval={billingInterval || 'YEARLY'}
              onChange={onBillingIntervalChange}
            />
          ) : null}
          {showAmount &&
          (!canMonthly || billingIntervalFeatureFlag?.includes('ALL')) ? (
            <BoldText data-test-id="plan-price">
              &nbsp;&nbsp;{formattedAmount}&nbsp;
              <Color>{formattedBillingTextForUpgrade}</Color>
            </BoldText>
          ) : null}
          <Button
            data-test-id={`${plan.toLowerCase()}-subscribe`}
            onClick={onSubscribe}
          >
            Subscribe
          </Button>
        </Bottom>
      ) : (
        <>
          {canMonthly && !billingIntervalFeatureFlag?.includes('ALL') ? (
            <SelectBillingInterval
              showAmount={showAmount}
              plan={plan}
              seats={seats}
              currency={currency}
              billingInterval={billingInterval || 'YEARLY'}
              onChange={onBillingIntervalChange}
            />
          ) : null}
          {showAmount &&
          (!canMonthly || billingIntervalFeatureFlag?.includes('ALL')) ? (
            <BoldText data-test-id="plan-price">
              &nbsp;&nbsp;{formattedAmount}&nbsp;
              <Color>{formattedBillingTextForUpgrade}</Color>
            </BoldText>
          ) : null}
          <Button
            data-test-id={`${plan.toLowerCase()}-subscribe`}
            onClick={onSubscribe}
          >
            Subscribe
          </Button>
        </>
      )}
    </Container>
  );
};

export default memo(Plan);
