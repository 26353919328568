import { useCallback, useMemo } from 'react';
import { from, useMediaQuery } from 'styles/media';
import currencyjs from 'currency.js';
import { LogicProps, Props } from './types';
import { useQuotaConditionsModalTrigger } from '../../containers/Common/WrongPlanChoice/QuotaConditionsModal/useQuotaConditionsModalTrigger';

export function getFormattedValue(value: number, currency: Props['currency']) {
  const formatSettings = (() => {
    const upperCaseCurrency = currency?.toUpperCase();
    switch (upperCaseCurrency) {
      case 'EUR':
        return { symbol: '€', pattern: '#!' };
      case 'INR':
        return { symbol: '₹', pattern: '!#' };
      default:
      case 'USD':
        return { symbol: '$', pattern: '!#' };
    }
  })();
  const formattedValue = currencyjs(value, {
    symbol: formatSettings.symbol,
    precision: value % 1 !== 0 ? 1 : 0, // using modulo 1 removes the decimal part
    pattern: formatSettings.pattern,
  }).format();
  return formattedValue;
}

const useLogic = ({ amount, plan, seats }: LogicProps) => {
  const isMobile = !useMediaQuery(from.tablet);
  const isTeam = plan === 'TEAM' || plan === 'UNLIMITED';
  const showAmount =
    amount &&
    amount > 0 &&
    (plan === 'PERSONAL' ||
      plan === 'PROFESSIONAL' ||
      (plan === 'TEAM' && seats && seats > 1));
  const showSeats = plan === 'TEAM' && seats && seats > 1;

  const title = useMemo(() => {
    switch (plan) {
      case 'FREE':
        return 'Free plan';
      case 'PERSONAL':
        return 'Personal plan';
      case 'PROFESSIONAL':
        return 'Professional plan';
      case 'TEAM':
        return 'Team plan';
      case 'UNLIMITED':
        return 'Custom plan';
      default:
        return '';
    }
  }, [plan]);

  const { trigger: triggerQuotaModal } = useQuotaConditionsModalTrigger();
  const openUserQuotaExplanationLink = useCallback(() => {
    window.open(
      'https://support.yet-another-mail-merge.com/hc/en-us/articles/210730829#Paid-version',
    );
  }, []);

  const isWorkspace = useMemo(() => {
    return plan === 'TEAM' || plan === 'PROFESSIONAL' || plan === 'UNLIMITED';
  }, [plan]);

  return {
    isMobile,
    isTeam,
    showAmount,
    showSeats,
    title,
    triggerQuotaModal,
    openUserQuotaExplanationLink,
    isWorkspace,
  };
};

export default useLogic;
